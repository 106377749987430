import React from 'react';
import PropTypes from 'prop-types';
import { colorGray, colorGrayLight2 } from 'styles/layout/colors';

const ProgressLoader = ({ size = 16, color = 'dark' }) => (
  <div
    className="inline-block animate-spin"
    role="progressbar"
    style={{ width: size * 2, height: size * 2 }}
  >
    <svg
      className="block"
      viewBox={`${size * 1.1} ${size * 1.1} ${size * 2.2} ${size * 2.2}`}
    >
      <circle
        className="fill-none stroke-[3px] animate-stroke"
        style={{ strokeLinecap: 'round' }}
        cx={size * 2.2}
        cy={size * 2.2}
        r={size * 1.01}
        fill="none"
        stroke={color === 'dark' ? colorGray : colorGrayLight2}
        strokeWidth={size / 5}
      />
    </svg>
  </div>
);

ProgressLoader.propTypes = {
  size: PropTypes.number,
  color: PropTypes.oneOf(['dark', 'dark-light']),
};

export default ProgressLoader;
