import React from 'react';
import PropTypes from 'prop-types';

import { colorGray } from 'styles/layout/colors';

const IconFacebook2 = ({
  color = colorGray,
  width = '25',
  height = '24',
  className = '',
}) => (
  <svg width={width} height={height} viewBox="0 0 25 24" className={className}>
    <defs>
      <polygon points="0.3562 0 24.356 0 24.356 24 0.3562 24" />
    </defs>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g>
        <g />
        <path
          d="M23.0322,0 L1.6792,0 C0.9492,0 0.3562,0.593 0.3562,1.324 L0.3562,22.676 C0.3562,23.408 0.9492,24 1.6792,24 L13.1742,24 L13.1742,14.706 L10.0452,14.706 L10.0452,11.085 L13.1742,11.085 L13.1742,8.41 C13.1742,5.311 15.0682,3.625 17.8332,3.625 C19.1582,3.625 20.2972,3.722 20.6292,3.766 L20.6292,7.006 L18.7082,7.006 C17.2082,7.006 16.9162,7.727 16.9162,8.777 L16.9162,11.088 L20.5002,11.088 L20.0352,14.718 L16.9162,14.718 L16.9162,24 L23.0312,24 C23.7632,24 24.3562,23.408 24.3562,22.676 L24.3562,1.324 C24.3562,0.593 23.7632,0 23.0322,0"
          fill={color}
        />
      </g>
    </g>
  </svg>
);

IconFacebook2.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default IconFacebook2;
