/* eslint-disable react/prop-types */
import React from 'react';
import useSuspenseQuery from 'commons/useSuspenseQuery';

// For legacy class components that cannot use hooks, this HOC can be used to
// load API data from Apollo Client
const withApolloSuspenseQuery = (
  query,
  queryOptions = {}
) => ComponentToWrap => {
  const WithApolloSuspenseQuery = props => {
    const { options, name } = queryOptions;

    // If options is a function, it needs to be executed with current props
    const processedOptions =
      typeof options === 'function' ? options(props) : options;

    const { data, error, fetchMore, loading, refetch } = useSuspenseQuery(
      query,
      processedOptions
    );

    const dataProps = name
      ? {
          [name]: {
            ...data,
            error,
            fetchMore,
            loading,
            refetch,
          },
        }
      : {
          data: {
            ...data,
            error,
            fetchMore,
            loading,
            refetch,
          },
        };

    WithApolloSuspenseQuery.displayName = `withApolloSuspenseQuery(${ComponentToWrap.displayName ||
      ComponentToWrap.name})`;

    return <ComponentToWrap {...props} {...dataProps} />;
  };

  return WithApolloSuspenseQuery;
};

export default withApolloSuspenseQuery;
