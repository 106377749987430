import React from 'react';
import PropTypes from 'prop-types';

import { colorGray } from 'styles/layout/colors';

const IconLinkedIn = ({
  color = colorGray,
  width = '25',
  height = '24',
  className = '',
}) => (
  <svg width={width} height={height} viewBox="0 0 25 24" className={className}>
    <defs>
      <polygon points="0 0 23.9996 0 23.9996 24 0 24" />
    </defs>

    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g>
        <path
          d="M22.2246,0 L1.7716,0 C0.7916,0 -0.0004,0.774 -0.0004,1.729 L-0.0004,22.271 C-0.0004,23.227 0.7916,24 1.7716,24 L22.2216,24 C23.2006,24 23.9996,23.227 23.9996,22.271 L23.9996,1.729 C23.9996,0.774 23.2006,0 22.2216,0 L22.2246,0 Z M3.5556,20.452 L7.1196,20.452 L7.1196,9 L3.5556,9 L3.5556,20.452 Z M5.3366,7.433 C4.1936,7.433 3.2746,6.507 3.2746,5.368 C3.2746,4.23 4.1946,3.305 5.3366,3.305 C6.4766,3.305 7.4016,4.23 7.4016,5.368 C7.4016,6.507 6.4756,7.433 5.3366,7.433 L5.3366,7.433 Z M20.4476,20.452 L16.8926,20.452 L16.8926,14.883 C16.8926,13.555 16.8666,11.846 15.0406,11.846 C13.1886,11.846 12.9056,13.291 12.9056,14.785 L12.9056,20.452 L9.3506,20.452 L9.3506,9 L12.7646,9 L12.7646,10.561 L12.8106,10.561 C13.2876,9.661 14.4486,8.711 16.1806,8.711 C19.7826,8.711 20.4486,11.081 20.4486,14.166 L20.4486,20.452 L20.4476,20.452 Z"
          fill={color}
        />
      </g>
    </g>
  </svg>
);

IconLinkedIn.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default IconLinkedIn;
