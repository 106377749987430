import React from 'react';
import PropTypes from 'prop-types';

import { colorGray } from 'styles/layout/colors';

const IconYoutube = ({
  color = colorGray,
  width = '25',
  height = '18',
  className = '',
}) => (
  <svg width={width} height={height} viewBox="0 0 25 18" className={className}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g>
        <path
          d="M10.3516,12.6011 L10.3516,5.4081 L16.6156,9.0101 L10.3516,12.6011 Z M24.2376,3.2051 C23.9546,2.1921 23.1636,1.4001 22.1496,1.1171 C20.2796,0.616085814 12.7526,0.616085814 12.7526,0.616085814 C12.7526,0.616085814 5.2466,0.6061 3.3576,1.1171 C2.3436,1.4001 1.5516,2.1921 1.2696,3.2051 C0.9146,5.1201 0.7406,7.0631 0.747396961,9.0101 C0.7416,10.9501 0.9156,12.8861 1.2696,14.7931 C1.5516,15.8061 2.3436,16.5981 3.3576,16.8811 C5.2256,17.3831 12.7526,17.3831 12.7526,17.3831 C12.7526,17.3831 20.2586,17.3831 22.1496,16.8811 C23.1636,16.5981 23.9546,15.8061 24.2376,14.7931 C24.5826,12.8851 24.7496,10.9491 24.7376,9.0101 C24.7516,7.0631 24.5836,5.1201 24.2376,3.2051 L24.2376,3.2051 Z"
          fill={color}
        />
      </g>
    </g>
  </svg>
);

IconYoutube.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default IconYoutube;
