import React from 'react';
import PropTypes from 'prop-types';

import { colorGray } from 'styles/layout/colors';

const IconPinterest = ({
  color = colorGray,
  width = '25',
  height = '24',
  className = '',
}) => (
  <svg width={width} height={height} viewBox="0 0 25 24" className={className}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g>
        <path
          d="M12.5029,0 C5.8819,0 0.5149,5.367 0.5149,11.987 C0.5149,17.066 3.6729,21.404 8.1329,23.149 C8.0269,22.2 7.9339,20.746 8.1739,19.71 C8.3929,18.773 9.5799,13.753 9.5799,13.753 C9.5799,13.753 9.2209,13.033 9.2209,11.972 C9.2209,10.309 10.1879,9.061 11.3889,9.061 C12.4129,9.061 12.9059,9.83 12.9059,10.749 C12.9059,11.778 12.2539,13.316 11.9149,14.741 C11.6299,15.934 12.5149,16.906 13.6899,16.906 C15.8169,16.906 17.4579,14.661 17.4579,11.419 C17.4579,8.558 15.3949,6.55 12.4489,6.55 C9.0399,6.55 7.0409,9.112 7.0409,11.749 C7.0409,12.782 7.4349,13.892 7.9299,14.49 C8.0279,14.61 8.0419,14.715 8.0149,14.835 C7.9249,15.21 7.7219,16.034 7.6809,16.198 C7.6279,16.423 7.5089,16.469 7.2789,16.363 C5.7849,15.673 4.8469,13.485 4.8469,11.717 C4.8469,7.941 7.5949,4.465 12.7669,4.465 C16.9249,4.465 20.1589,7.432 20.1589,11.388 C20.1589,15.523 17.5519,18.85 13.9259,18.85 C12.7119,18.85 11.5709,18.221 11.1679,17.471 L10.4189,20.319 C10.1489,21.364 9.4149,22.671 8.9209,23.465 C10.0439,23.81 11.2269,24 12.4709,24 C19.0779,24 24.4559,18.635 24.4559,12.013 C24.4559,5.39 19.0779,0.026 12.4709,0.026 L12.5029,0 Z"
          fill={color}
        />
      </g>
    </g>
  </svg>
);

IconPinterest.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default IconPinterest;
