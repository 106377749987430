import React from 'react';
import PropTypes from 'prop-types';
import { colorGray } from 'styles/layout/colors';

const IconHouzz = ({
  color = colorGray,
  width = '15',
  height = '24',
  className = '',
}) => (
  <svg width={width} height={height} viewBox="0 0 15 24" className={className}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g>
        <polygon
          fill={color}
          points="14.8018 12 14.8018 20 7.8708 24 7.8708 15.998 0.9418 20 0.9418 3.998 7.8708 0 7.8708 8 0.9438 12 7.8708 15.998 7.8708 8 14.8018 3.998"
        />
      </g>
    </g>
  </svg>
);

IconHouzz.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default IconHouzz;
